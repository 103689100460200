import React, {Component} from "react";
import MenuAdmin from "../../components/menu-admin";
import * as Constants from "../../utils/constants";
import axios from "axios";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import * as Images from "../../components/images";
import * as Validator from "../../utils/validator";
import CheckButton from 'react-validation/build/button';
import {NotificationManager} from 'react-notifications';
import LanguageSwitch from "../../components/languageSwitch";
import {withTranslation} from "react-i18next";
import MenuSettingAdmin from "../../components/menu/menu-setting-admin";

class Parameter extends Component {
  constructor(props) {
    super(props);
    const {t, i18n} = props;
    // Init component state
    this.state = {
      tableHeader: [
        'common.item',
        'common.value'
      ],
      params: []
    };

    this.getData();
    this.editParamSubmit.bind(this);
  }

  getHeader(contentType) {
    let headers = {
      'Content-Type': contentType,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
      'Authorization': 'Bearer ' + JSON.parse(
          localStorage.getItem('user')).accessToken
    }
    return {headers: headers};
  }

  renderItemHeaderTable(data, t) {
    return data.map((item, index) => {
      return <td key={index}>{t(item)}</td>;
    });
  }

  handleChange(field, index, e) {
    let item = this.state.params[index];
    item[field] = e.target.value;
    this.setState({item});
  }

  handleEdit(index) {
    this.state.params[index].editMode = true;
    this.setState(this.state.params);
  }

  handleDelete(item) {
  }

  editParamSubmit(e, index, t) {
    e.preventDefault();
    let header = this.getHeader('application/json');
    if (this['checkBtnEdit' + index].context._errors.length === 0) {
      let item = this.state.params[index];

      axios.post(Constants.MASTER_DATA_PARAMETTER_URL, item, header)
      .then(response => {
        this.state.params[index].editMode = false;
        this.setState(this.state.params);
        NotificationManager.success(t('avatar.notifi_success'), '', 2000);
      }).catch(function (error) {
        NotificationManager.error(t('avatar.notifi_error2'), t('common.error'),
            2000);
      });
    }
  }

  getDescript(key, t) {
    switch (key) {
      case "分": return t('common.des_refresh_time');
      case "秒": return t('common.des_clear_emergency_time');
        
      default: break;
    }
  }

  getUnit(key, t) {
    switch (key) {
      case "分": return t('common.unit');
      case "秒": return t('common.sec');
    
      default: break;
    }
  }

  renderItemTable(data, t) {
    return data.map((item, index) => {
      return (
          <tr key={index}>
            <td>
              <p style={{marginLeft: '10px'}}>{this.getDescript(item.unit, t)}</p>
            </td>
            <td className='inline-edit'>
              <p className={this.state.params[index].editMode ? "hidden" : "value-content"}>
                {item.value} {this.getUnit(item.unit, t)}
              </p>

              <Form className={this.state.params[index].editMode
                  ? "edit-user f-column f-center" : "hidden"}
                    onSubmit={(e) => this.editParamSubmit(e, index, t)}
                    ref={c => {
                      this.formEdit = c
                    }}>

                <div className="item">
                  <Input
                      type="text"
                      className="dps-input"
                      name="corpName"
                      value={this.state.params[index].value}
                      min={item.min}
                      max={item.max}
                      validations={[Validator.required, Validator.number, Validator.range]}
                      onChange={this.handleChange.bind(this, "value", index)}
                      message={t("common.message_set_value")}
                  />
                </div>

                <CheckButton style={{display: 'none'}} ref={c => {
                  this['checkBtnEdit' + index] = c
                }}/>
                <button type="submit"
                        className="btn-small save position-relative">
                  <div className="fix-icon-center-ios">
                    <Images.Icons name="IconSave"/>
                  </div>
                </button>
              </Form>

              <button className={this.state.params[index].editMode ? "hidden"
                  : "btn-small edit position-relative"}
                      onClick={this.handleEdit.bind(this, index)}>
                <div className="fix-icon-center-ios">
                  <Images.Icons name="IconEdit"/>
                </div>
              </button>
            </td>
          </tr>
      );
    });
  }

  getData() {
    axios.get(Constants.MASTER_DATA_URL + 'param',
        this.getHeader('application/json'))
    .then(res => {
      this.setState({'params': res.data});
    });
  }

  componentDidMount() {
  }

  //Render Ui for screen
  render() {
    const {t, i18n} = this.props;
    return (
        <>
          <div className="dps-menu ">
            <MenuAdmin currentRouter={this.props.location.pathname}/>
          </div>
          <div className="dps-content admin-page">
            <div className="dps-content-area">
              <div className="dps-title main-title">
                <h1>{t('avatar.main_title')}</h1>
                <LanguageSwitch/>
              </div>
              <div className="dps-main-content">

                <div className="dps-main-content-area">
                  <MenuSettingAdmin currentRouter={this.props.location.pathname}></MenuSettingAdmin>
                  <div className="dps-tabs-content admin-setting">
                    <table className="dps-table params-table ">
                      <thead>
                      <tr className="header">
                        {this.renderItemHeaderTable(this.state.tableHeader, t)}
                      </tr>
                      </thead>
                      <tbody className="scroll-bar">
                      {this.renderItemTable(this.state.params, t)}
                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
    );
  }
}

export default withTranslation("dps")(Parameter);