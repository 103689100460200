import { memo, useContext, useEffect, useCallback, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { KeepScale } from "react-zoom-pan-pinch";
import { MapContext } from "../Tab/planMapKirihaMode";
import * as Constants from "../../../utils/constants";
import { IconDefaultChargingStation, IconMarkerPreview } from "../../images"
import { cloneDeep } from "lodash";

const DeviceViewComponent = ({ isMove }) => {
  const {
    originPosition,
    zoom,
    markerDataCsv,
    chargingStationsView,
    stationTypes,
    angle,
    ratioAxis,
    markerKirihaData,
    updateMarkerKirihaData,
    onChangeRouterSt
  } = useContext(MapContext);

  const [isShowFormEdit, setIsShowFormEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const [positionFormEdit, setPositionFormEdit] = useState(null);

  const modalFormSettingMarker = useRef(document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(modalFormSettingMarker.current);

    return () => {
      document.body.removeChild(modalFormSettingMarker.current);
    };
  }, []);

  const rotatePoint = (currentPoint) => {
    const alpha = (angle * Math.PI) / 180;
    const cosAlpha = Math.cos(alpha);
    const sinAlpha = Math.sin(alpha);

    const deltaX = currentPoint.x * ratioAxis;
    const deltaY = currentPoint.y * ratioAxis * -1;

    const xPrime = deltaX * cosAlpha - deltaY * sinAlpha;
    const yPrime = deltaX * sinAlpha + deltaY * cosAlpha;

    return({ x: xPrime, y: yPrime });
  }

  const renderChargingStation = () => {
    return chargingStationsView.map(station => {
      if (station.x !== null && station.y !== null) {
        const point = rotatePoint({ x: station.x, y: station.y });
        const url = stationTypes.find(item => item.id === station.stationTypeId)?.imagePath;

        return (
          <div 
            key={station.id}
            className="station-map-editer"
            style={{
              transform: `translate(
                calc(${originPosition.left * zoom + point.x}px - 50%), 
                calc(${originPosition.top * zoom + point.y}px - 50%)
              )`
            }}
          >
            <KeepScale>
              <div className="station-icon" style={url ? undefined : { background: "transparent", boxShadow: "unset" }}>
                {url ?
                  <img 
                    src={Constants.getResourceUrl() + url} 
                    alt="station" 
                  />
                  :
                  <IconDefaultChargingStation />
                }
              </div>
            </KeepScale>
          </div>
        )
      }
    })
  }

  const renderMarkerCsv = () => {
    return markerDataCsv.map(marker => {
      if (marker.id && marker.x !== null && marker.y !== null) {
        const point = rotatePoint({ x: marker.x, y: marker.y });
        return (
          <div 
            key={Number(marker.id) + Number(marker.x)}
            className="marker-map-editer"
            style={{
              transform: `translate(
                calc(${originPosition.left * zoom + point.x}px - 50%),
                calc(${originPosition.top * zoom + point.y}px - 50%)
              )`
            }}
            onClick={(e) => handleShowFormEditMarkerData(e, marker)}
          >
            <div className="marker-icon">
              <KeepScale>
                <IconMarkerPreview />
              </KeepScale>
            </div>
          </div>
        )
      }
    })
  } 

  const renderFormEditMarker = useCallback(() => {
    if (!isShowFormEdit) return null;

    const topPs = positionFormEdit.top;
    const leftPs = positionFormEdit.left;

    return ReactDOM.createPortal(
      <div 
        className="form-edit-marker-data" 
        style={{
          top: topPs - 188,
          left: leftPs + 140 > window.innerWidth ? undefined : leftPs + 20,
          right: leftPs + 140 > window.innerWidth ? 10 : undefined,
        }}
      >
        <div className="form-head">
          <label>Marker {dataEdit.id}</label>
          <button className="btn-close" onClick={handleCloseFormEdit}></button>
        </div>
        <div className="row-control">
          <label>x [m]</label>
          <input type="number" name="x" value={dataEdit.x || ""} onChange={onChangeFormEditMarker} />
        </div>
        <div className="row-control">
          <label>y [m]</label>
          <span style={{}}>0</span>
        </div>
        <div className="row-control">
          <label>z [m]</label>
          <input type="number" name="z" value={dataEdit.z || ""} onChange={onChangeFormEditMarker} />
        </div>

        <button className="btn-submit" onClick={handleEditMarkerData}>Ok</button>
      </div>
      , modalFormSettingMarker.current
    );
  }, [dataEdit, isShowFormEdit])

  const handleShowFormEditMarkerData = (e, data) => {
    const { left, top } = e.target.getBoundingClientRect();
    setPositionFormEdit({ left, top });
    setIsShowFormEdit(true);
    setDataEdit(data);
  }

  const handleCloseFormEdit = () => {
    setPositionFormEdit(null);
    setIsShowFormEdit(false);
    setDataEdit(null);
  }

  const onChangeFormEditMarker = (e) => {
    const { name, value } = e.target;
    setDataEdit({ ...dataEdit, [name]: value });
  }

  const handleEditMarkerData = async () => {
    const dataCsvArr = cloneDeep(markerKirihaData).map(item => item.split(","));
    const index = dataCsvArr.findIndex(item => item[0] === dataEdit.id);
    
    if (index !== -1) {
      const newMarker = dataCsvArr[index];
      newMarker[1] = dataEdit.x;
      newMarker[2] = dataEdit.z;
      dataCsvArr.splice(index, 1, newMarker);

      updateMarkerKirihaData(dataCsvArr.map(item => item.join(",")));
      onChangeRouterSt();
      handleCloseFormEdit();
    }
  }

  return (
    <div className="device-preview" style={ isMove ? { pointerEvents: "none" } : undefined}>
      {renderChargingStation()}
      {renderMarkerCsv()}

      {renderFormEditMarker()}
    </div>
  )
}

export default memo(DeviceViewComponent);