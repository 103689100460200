import React, { useEffect, useRef, useState } from "react";
import update from "immutability-helper";
import axios from "axios";
import pdfjs from "pdfjs-dist/legacy/build/pdf.js";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";

import * as Images from "../images";
import * as Constants from "../../utils/constants";
import PlanFlightRouteSetting from "./Tab/planFlightRouteSetting";
import PlanMapKirihaMode from "./Tab/planMapKirihaMode";
import AuthService from "../../services/auth.service";
import { FE_VER } from "../../utils/constants";
import { Nav, Tab } from "react-bootstrap";
import { getHeader } from "../../services/api.service";
import { generateUUIDv4 } from "../../utils/utils";

const initialMarkerState = {
  id: 1,
  hover_t: 0,
  isStation: "false",
  direction: "right",
  angle: 0,
  marker_distance: 0,
  wall_distance: 5,
  marker_distance_mechanism: "sensor",
  marker_size: 20,
  height: 3,
  mTemp1: "",
  mTemp2: "",
  action: {
    type: "none",
    rotate: 0,
    hold_t: 0,
  },
  kiriha: false,
  markerId: generateUUIDv4()
};

const initialState = {
  user: {
    corpId: "",
    corpName: "",
    division: "",
    place: "",
  },
  date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  app: {
    ver: ``,
  },
  drone: {
    type: "",
    frm_ver: "",
  },
  flight: {
    flight_plan_no: "",
    flight_plan_name: "",
    speed: 1,
    marker_recog_t: null,
    port: {
      takeoff: "",
      landon: "",
    },
    fTemp: "",
    emergency_marker: 999,
    flight_marker: [initialMarkerState],
  },
  kirihaMode: false,
  captureDistance: 20,
};

const initialMapEditorContent = {
  scale: {},
  firstMarker: {},
  direction: null,
  markers: []
}

const initialMapEditorContentKiriha = {
  zoom: 0,
  originPosition: {
    left: 0,
    top: 0
  },
  calibration : {
    left: 0,
    top: 0
  },
  axis: {
    x: null,
    y: null
  }
}

const initStatusChangeFlag = {
  routerSt: false,
  mapAdvanceSt: false,
  mapSt: false,
  mapKirihaSt: false,
}

const initStatusChangeKirihaFlag = {
  face: false,
  marker: false,
}

const faceKirahaMode = "face";
const markerKirihaMode = "marker";

const PlanContent = (props) => {
  const { action, tabView, plan: planDefault, droneTypes, handleChangeTab, createCallBack, handleChangeAction } = props;
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const KIRIHA_MODE = JSON.parse(localStorage.getItem("user")).kirihaMode;

  const { t } = useTranslation();

  const [chargingStation, setChargingStation] = useState([]);
  const [plan, setPlan] = useState(null);
  const [planRouterSetting, setPlanRouterSetting] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorElement, setErrorElement] = useState("");
  const [statusChangeFlag, setStatusChangeFlag] = useState(initStatusChangeFlag);

  // Map advance
  const [imageMapEditorFileChange, setImageMapEditorFileChange] = useState(null);
  const [mapEditorContent, setMapEditorContent] = useState(initialMapEditorContent);

  // Map
  const [imageMapFileChange, setImageMapFileChange] = useState(null);
  const [mapContent, setMapContent] = useState([]);

  // Kiriha Data
  const [statusChangeKirihaFlag, setStatusChangeKirihaFlag] = useState(initStatusChangeKirihaFlag);
  const [faceKirihaData, setFaceKirihaData] = useState([]);
  const [markerKirihaData, setMarkerKirihaData] = useState([]);
  const [mapEditorContentKiriha, setMapEditorContentKiriha] = useState(initialMapEditorContentKiriha);

  // Duplicate plan
  const [isDuplicate, setIsDuplicate] = useState(false);

  const inputPlanNoRef = useRef(null);


  const developerMode = Boolean(localStorage.getItem("developerMode") === "true");

  useEffect(() => {
    if (planDefault && action === "edit") {
      const { planNo, planContent, mapEditorContent, mapEditorContentKiriha, mapContent } = planDefault;

      const planContentCustom = cloneDeep(planContent);
      const { flight_marker } = planContentCustom.flight;

      const indexMarkerKiriha = flight_marker.findIndex(item => item.kiriha);

      if (indexMarkerKiriha !== -1 && KIRIHA_MODE) {
        const kirihaRecord = cloneDeep(initialMarkerState);
        kirihaRecord.id = "kiriha";

        flight_marker.splice(indexMarkerKiriha + 1, 0, kirihaRecord);
      }

      getKirihaData(planNo);

      setPlan(planDefault);
      setPlanRouterSetting(planContentCustom);
      setMapContent(mapContent);
      setMapEditorContent(prev => ({ ...prev, ...mapEditorContent}));
      setMapEditorContentKiriha(prev => ({ ...prev, ...mapEditorContentKiriha }));
    } else {
      if(droneTypes.length) {
        setPlanRouterSetting(prev => ({
          ...prev,
          drone: { ...prev.drone, type: droneTypes[0].name }
        }));
      }
    }
    getChargingStationList();
  }, [])

  useEffect(() => {
    setErrorMessage("");
    setErrorElement("");
  }, [planRouterSetting.kirihaMode])

  useEffect(() => {
    if (isDuplicate) {
      inputPlanNoRef.current.focus();
    }
  }, [isDuplicate])

  const getChargingStationList = () => {
    axios.get(Constants.CHARGING_STATION_URL, getHeader()).then(res => {
      setChargingStation(res.data);
    })
  };

  const getKirihaData = (planNo) => {
    const promiseGetFaceCsvFile = axios.get(Constants.CSV_CUSTOMER, {
      params : {
        corpId: currentUser.corpId,
        flightPlanNo: planNo,
        mode: faceKirahaMode
      },
      headers : { corpKey: currentUser.corpKey }
    });

    const promiseGetMarkerCsvFile = axios.get(Constants.CSV_CUSTOMER, {
      params : {
        corpId: currentUser.corpId,
        flightPlanNo: planNo,
        mode: markerKirihaMode
      },
      headers : { corpKey: currentUser.corpKey }
    });

    Promise.allSettled([promiseGetFaceCsvFile, promiseGetMarkerCsvFile]).then(ressponseArr => {
      if (ressponseArr[0].status === "fulfilled" && ressponseArr[0].value) {
        const dataMap = ressponseArr[0].value.data.split('\n');
        setFaceKirihaData(dataMap);
      } else {
        setFaceKirihaData([]);
      }

      if (ressponseArr[1].status === "fulfilled" && ressponseArr[1].value) {
        const dataMap = ressponseArr[1].value.data.split('\n');
        setMarkerKirihaData(dataMap);
      } else {
        setMarkerKirihaData([]);
      }
    })
  }

  const getFileS3 = async (folderName, filename) => {
    const response = await axios.get(Constants.PLAN_FLIE_S3, { headers: getHeader(), params: { folderName }, responseType: "blob"});
    if (response.data) {
      const blob = response.data;
      return new File([blob], filename, { type: blob.type });
    }
  }

  const handleChangeImageFile = (e, tab) => {
    if (e.target.files[0]) {
      if (e.target.files[0].type !== "application/pdf") {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        if (tab === "map") setImageMapFileChange(e.target.files[0]);
        if (tab === "map-advance") setImageMapEditorFileChange(e.target.files[0]);

        reader.onloadend = function (e) {
          let url = reader.result;
          if (tab === "map") {
            setPlan(update(plan, { mapImage: { $set: url } }));
            setMapContent([]);
            setStatusChangeFlag(prev => ({ ...prev, mapSt: true }));
          }

          if (tab === "map-advance") {
            setPlan(update(plan, { mapEditorImage: { $set: url } }));
            // !planRouterSetting.kirihaMode && setMapEditorContent(initialMapEditorContent);
            setMapEditorContentKiriha(initialMapEditorContentKiriha);
            setStatusChangeFlag(prev => ({ ...prev, mapKirihaSt: true }));
          }
        };
      } else {
        let file = e.target.files[0];
        let fileReader = new FileReader();
        fileReader.onload = function () {
          let pdfData = new Uint8Array(this.result);
          // Using DocumentInitParameters object to load binary data.
          let loadingTask = pdfjs.getDocument({ data: pdfData });
          loadingTask.promise.then(
            function (pdf) {
              console.log("PDF loaded");

              // Fetch the first page
              let pageNumber = 1;
              pdf.getPage(pageNumber).then(function (page) {
                console.log("Page loaded");

                let scale = 1.0;
                let viewport = page.getViewport({ scale: scale });

                // Prepare canvas using PDF page dimensions
                let canvas = document.createElement("canvas");
                let context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // Render PDF page into canvas context
                let renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                };
                let renderTask = page.render(renderContext);
                renderTask.promise.then(function () {
                  let url = canvas.toDataURL();
                  // Split the base64 string in data and contentType
                  let block = url.split(";");
                  // Get the content type
                  let contentType = block[0].split(":")[1]; // In this case "image/gif"
                  // get the real base64 content of the file
                  let realData = block[1].split(",")[1]; // In this case "iVBORw0KGg...."

                  // Convert to blob
                  let blob = b64toBlob(realData, contentType);

                  if (tab === "map") {
                    setImageMapFileChange(blob);
                    setPlan(update(plan, { mapImage: { $set: url } }));
                    setMapContent([]);
                    setStatusChangeFlag(prev => ({ ...prev, mapSt: true }));
                  }
                  if (tab === "map-advance") {
                    setImageMapEditorFileChange(blob);
                    setPlan(update(plan, { mapEditorImage: { $set: url } }));
                    // !planRouterSetting.kirihaMode && setMapEditorContent(initialMapEditorContent);
                    setMapEditorContentKiriha(initialMapEditorContentKiriha);
                    setStatusChangeFlag(prev => ({ ...prev, mapKirihaSt: true }));
                  }
                });
              });
            },
            function (reason) {
              // PDF loading error
              console.error(reason);
            }
          );
        };
        fileReader.readAsArrayBuffer(file);
      }
    }
  };

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const handleMakerChange = (markers) => {
    setMapContent(markers);
    setStatusChangeFlag(prev => ({
      ...prev, mapSt: true
    }));
  };

  const handleUpdateMapEditorContentKiriha = (newData) => {
    setStatusChangeFlag(prev => ({ ...prev, mapKirihaSt: true }))
    setMapEditorContentKiriha({ ...mapEditorContentKiriha, ...newData });
  }

  const handleInputChange = (event) => {
    // reset error states
    setErrorElement("");
    setErrorMessage("");
    setStatusChangeFlag(prev => ({ ...prev, routerSt: true }));
    
    let { name, value } = event.target;
    if (name === "id" || name === "flight_plan_no") {
      value = Math.floor(value);
    }

    switch (name) {
      case "id":
        setPlanRouterSetting(update(planRouterSetting, { flight: { id: { $set: value } } }));
        return;
      case "ver":
        setPlanRouterSetting(update(planRouterSetting, { app: { ver: { $set: value } } }));
        return;
      case "created_time":
        setPlanRouterSetting(update(planRouterSetting, { date: { $set: value } }));
        return;
      case "flight_plan_no":
        setPlanRouterSetting(update(planRouterSetting, { flight: { flight_plan_no: { $set: value ? value : "" } } }));
        return;
      case "flight_plan_name":
        setPlanRouterSetting(update(planRouterSetting, { flight: { flight_plan_name: { $set: value } } }));
        return;
      case "emergency_marker":
        setPlanRouterSetting(update(planRouterSetting, { flight: { emergency_marker: { $set: value } } }));
        return;
      case "fTemp":
        setPlanRouterSetting(update(planRouterSetting, { flight: { fTemp: { $set: value } } }));
        return;
      case "drone_type":
        setPlanRouterSetting(update(planRouterSetting, { drone: { type: { $set: value } } }));
        return;
      case "speed":
        setPlanRouterSetting(update(planRouterSetting, { flight: { speed: { $set: value } } }));
        return;
      case "frm_ver":
        setPlanRouterSetting(update(planRouterSetting, { drone: { frm_ver: { $set: value } } }));
        return;
      case "place":
        setPlanRouterSetting(update(planRouterSetting, { user: { place: { $set: value } } }));
        return;
      case "division":
        setPlanRouterSetting(update(planRouterSetting, { user: { division: { $set: value } } }));
        return;
      case "capture_distance":
        setPlanRouterSetting(update(planRouterSetting, { captureDistance: { $set: value } }));
        return;
      default:
        return;
    }
  };

  const validateForm = () => {
    const planNo = Number.parseInt(planRouterSetting.flight.flight_plan_no);
    if (isNaN(planNo) || planNo < 101 || planNo > 299) {
      setErrorMessage(t("plan.error.invalid_plan_no"));
      setErrorElement("flight_plan_no");
      return false;
    }

    if (!planRouterSetting.flight.flight_plan_name) {
      setErrorMessage(t("plan.error.empty_plan_name"));
      setErrorElement("plan_name");

      return false;
    }
    if (!planRouterSetting.drone.type) {
      setErrorMessage(t("plan.error.invalid_drone_type"));
      setErrorElement("drone_type");
      return false;
    }

    const speed = Number.parseFloat(planRouterSetting.flight.speed);
    if (isNaN(speed) || speed <= 0) {
      setErrorMessage(t("plan.error.invalid_speed"));
      setErrorElement("speed");
      return false;
    }

    if (
      planRouterSetting.flight.flight_marker.length > 0 &&
      planRouterSetting.flight.flight_marker[0].direction &&
      !["landed", "left", "right"].includes(
        planRouterSetting.flight.flight_marker[0].direction
      )
    ) {
      setErrorMessage(t("plan.error.invalid_first_marker"));
      setErrorElement("0.direction");
      handleChangeTab("view");
      return false;
    }

    return planRouterSetting.flight.flight_marker.every((marker, index, arr) => {
      let markerId = marker.id !== "kiriha" ? parseFloat(marker.id) : marker.id;

      if (
        isNaN(markerId) ||
        !Number.isInteger(markerId) ||
        markerId < 1 ||
        markerId > 100
      ) {
        if (markerId === "kiriha" && planRouterSetting.kirihaMode) {
          const beforeMarker = arr[index - 1];
          const afterMarker = arr[index + 1];

          if (beforeMarker?.id !== afterMarker?.id) {
            setErrorMessage(t("plan.error.invalid_same_marker_id"));
            setErrorElement(index + ".id");
            return false;
          }

          return true
        };

        setErrorMessage(t("plan.error.invalid_marker_id"));
        setErrorElement(index + ".id");
        handleChangeTab("view");
        return false;
      }

      let markerHeight = parseFloat(marker.height);
      if (isNaN(markerHeight) || markerHeight < 0) {
        setErrorMessage(t("plan.error.invalid_marker_height"));
        setErrorElement(index + ".height");
        handleChangeTab("view");
        return false;
      }
      let markerHoverT = parseFloat(marker.hover_t);
      if (
        developerMode &&
        (isNaN(markerHoverT) || markerHoverT < 0 || markerHoverT > 999)
      ) {
        setErrorMessage(t("plan.error.invalid_hover_t"));
        setErrorElement(index + ".hover_t");
        handleChangeTab("view");
        return false;
      }
      let markerWallDistance = parseFloat(marker.wall_distance);
      if (isNaN(markerWallDistance) || markerWallDistance <= 0) {
        setErrorMessage(t("plan.error.invalid_wall_distance"));
        setErrorElement(index + ".wall_distance");
        handleChangeTab("view");
        return false;
      }
      if (!marker.direction) {
        setErrorMessage(t("plan.error.invalid_direction"));
        setErrorElement(index + ".direction");
        handleChangeTab("view");
        return false;
      }
      if (marker.angle === null || marker.angle === "") {
        setErrorMessage("Angle is empty");
        setErrorElement(index + ".angle");
        handleChangeTab("view");
        return false;
      }
      let markerDistance = parseFloat(marker.marker_distance);
      if (isNaN(markerDistance) || markerDistance < 0) {
        setErrorMessage(t("plan.error.invalid_marker_distance"));
        setErrorElement(index + ".marker_distance");
        handleChangeTab("view");
        return false;
      }
      return true;
    });
  };

  const generateCsvFile = (file) => {
    return new Blob([file.join('\n')], { type: 'text/csv;charset=utf-8;' })
  }

  const generateMarkerPosition = (data) => {
    const dataHandle = cloneDeep(data);
    dataHandle.shift();
    const markerPs = dataHandle.map(item => {
      const [id, x, z] = item.split(',');

      return {id, x, y: 0, z};
    })
    
    return markerPs;
  }

  const handleSubmit = () => {
    if (!validateForm()) return;

    const currentUser = AuthService.getCurrentUser();
    planRouterSetting.user.corpId = currentUser.corpId;
    planRouterSetting.user.corpName = currentUser.corpName;
    const frontendVer = FE_VER;
    if (planRouterSetting.app.ver === "") {
      planRouterSetting.app.ver = frontendVer;
    }

    const planRequest = cloneDeep(planRouterSetting);
    planRequest.flight.flight_marker.map((item) => {
      delete item.markerId;
      return item;
    });

    const indexMarkerKiriha = planRequest.flight.flight_marker.findIndex(marker => marker.id === "kiriha");

    if (indexMarkerKiriha !== -1) {
      planRequest.flight.flight_marker.splice(indexMarkerKiriha, 1);
    }

    const statusChangeFlagRequest = cloneDeep(statusChangeFlag);
    
    const stationList = planRequest.flight.flight_marker.filter(marker => marker.isStation !== "false").map(marker => {
      const station = chargingStation.find(item => item.stationId === marker.isStation);
      
      if (station && station.x !== null && station.y !== null) {
        return {
          stationId: station.stationId,
          stationTypeId: station.stationTypeId,
          x: station.x,
          y: station.y,
        }
      }
    })

    const formData = new FormData();
    formData.append("statusChangeFlag", JSON.stringify(statusChangeFlagRequest));
    formData.append("routerSetting", JSON.stringify(planRequest));
    formData.append("mapEditorContent", JSON.stringify(mapEditorContent));
    formData.append("mapEditorContentKiriha", JSON.stringify(mapEditorContentKiriha));
    formData.append("mapContent", JSON.stringify(mapContent));
    formData.append("stationPosition", JSON.stringify(stationList.filter(item => item)));

    if (statusChangeKirihaFlag.face && planRouterSetting.kirihaMode || isDuplicate) {
      formData.append("faceKirihaFile", generateCsvFile(faceKirihaData));
    }

    if (statusChangeKirihaFlag.marker || isDuplicate) {
      formData.append("markerKirihaFile", generateCsvFile(markerKirihaData));
      formData.append("markerPosition", JSON.stringify(generateMarkerPosition(markerKirihaData)));
    }

    if (imageMapEditorFileChange) formData.append("fileMapEditor", imageMapEditorFileChange);
    if (imageMapFileChange) formData.append("fileMap", imageMapFileChange);
    
    if (action === "edit") {
      formData.append("id", plan.id);

      axios.put(Constants.PLAN_UPDATE, formData, getHeader("multipart/form-data")).then(res => {
        NotificationManager.success(t("put.success"), "", 2000);
        setStatusChangeFlag(initStatusChangeFlag);
        setStatusChangeKirihaFlag(initStatusChangeKirihaFlag);
        setImageMapEditorFileChange(null);
        setImageMapFileChange(null);
        setPlan(res.data);
      }).catch(err => {
        NotificationManager.error(t("post.err"), "", 2000);
      })
    } else {
      axios.post(Constants.PLAN_CREATE_FLIGHT_URL, formData, getHeader("multipart/form-data")).then(res => {
        NotificationManager.success(t("post.success"), "", 2000);
        setStatusChangeFlag(initStatusChangeFlag);
        setStatusChangeKirihaFlag(initStatusChangeKirihaFlag);
        setPlan(res.data);
        createCallBack();
        setIsDuplicate(false);
        setImageMapEditorFileChange(null);
      }).catch(err => {
        NotificationManager.error(t("post.err"), "", 2000);
      })
    }
  }

  const handleDuplicatePlan = () => {
    confirmAlert({
      title: t("plan.dialog_duplicate_plan"),
      message: "",
      buttons: [
        {
          label: t("dialog_delete.option_no"),
        },
        {
          label: t("plan.duplicate_text"),
          onClick: async () => {
            handleChangeAction("add");
            setStatusChangeFlag(prev => ({ ...prev, routerSt: true }));
            setIsDuplicate(true);
            setPlanRouterSetting(update(planRouterSetting, { flight: {
              flight_plan_name: { $set: "copy " + planRouterSetting.flight.flight_plan_name }
            }}))

            const newFileMapEditor = await getFileS3(plan.mapEditorImage, `plan_${plan.planNo}_mapEditor_copy.png`);
            newFileMapEditor && setImageMapEditorFileChange(newFileMapEditor);
          },
        },
      ],
    });
  }

  const renderFormHeader = () => {
    const { 
      flight: {
        flight_plan_no,
        flight_plan_name,
        fTemp,
        emergency_marker,
        speed,
      },
      drone: {
        type,
      },
      user: {
        place,
        division
      },
      date
    } = planRouterSetting;

    const isDisableBtnSubmit = Object.values(statusChangeFlag).every(item => !item);

    return (
      <div className="form-head">
        <div className="form-head-row">
          <div className={"form-item form-plan_no" + (action === "edit" ? " form-disable" : "")}>
            <span>{t("map.flight_plan_number")}</span>
            <label>
              <input
                type="number"
                name="flight_plan_no"
                onChange={handleInputChange}
                min={101}
                max={299}
                step={1}
                value={flight_plan_no}
                ref={inputPlanNoRef}
              />
              <span className="error-message d-inline ml-4">{" "}{errorElement === "flight_plan_no" ? errorMessage : ""}</span>
            </label>
          </div>

          <div className="form-item form-plan_name">
            <span>{t("plan.drone.plan_name")}</span>
            <label>
              <input
                type="text"
                placeholder={t("plan.plan_name")}
                name="flight_plan_name"
                onChange={handleInputChange}
                value={flight_plan_name}
                maxLength={255}
              />
              <span className="error-message d-inline ml-4">{" "}{errorElement === "plan_name" ? errorMessage : ""}</span>
            </label>
          </div>
          
          {developerMode && 
            <>
              <div className="form-item form-fTemp">
                <span>{t("plan.drone.fTemp")}</span>
                <label>
                  <input
                    disabled={!developerMode}
                    type="text"
                    name="fTemp"
                    onChange={handleInputChange}
                    value={fTemp}
                  />
                  <span className="error-message d-inline ml-4">{" "}{errorElement === "emergency_marker" ? errorMessage : ""}</span>
                </label>
              </div>

              <div className="form-item form-emergency_marker">
                <span>{t("plan.drone.emergency_marker")}</span>
                <label>
                  <input
                    disabled={!developerMode}
                    type="number"
                    name="emergency_marker"
                    onChange={handleInputChange}
                    min="0"
                    value={emergency_marker}
                  />
                  <span className="error-message d-inline ml-4">{" "}{errorElement === "emergency_marker" ? errorMessage : ""}</span>
                </label>
              </div>

              <div className="form-item form-date form-disable">
                <span>{t("plan.app.date")}</span>
                <label>
                <input
                    disabled
                    name="created_time"
                    type="text"
                    onChange={handleInputChange}
                    value={date || moment(new Date())}
                  />
                  <span className="error-message d-inline ml-4">{" "}{errorElement === "date" ? errorMessage : ""}</span>
                </label>
              </div>
            </>
          }
        </div>

        <div className="form-head-row">
          <div className="form-item form-drone_type">
            <span>{t("plan.drone.drone_type")}</span>
            <label>
              <select
                name="drone_type"
                value={type}
                onChange={handleInputChange}
              >
                <option value="" disabled selected>
                  {t("common.please_select")}
                </option>
                {droneTypes.map((droneType, index) => {
                  return (
                    <option key={index} value={droneType.name}>
                      {droneType.name}
                    </option>
                  );
                })}
              </select>
              <Images.IconArrowDown />
              <span className="error-message d-inline ml-4">{" "}{errorElement === "drone_type" ? errorMessage : ""}</span>
            </label>
          </div>

          <div className="form-item form-speed">
            <span>{t("plan.drone.speed")}</span>
            <label>
              <input
                type="number"
                name="speed"
                onChange={handleInputChange}
                min="0"
                value={speed}
              />
              <span className="error-message d-inline ml-4">{" "}{errorElement === "speed" ? errorMessage : ""}</span>
            </label>
          </div>

          <div className="form-item form-place">
            <span>{t("plan.drone.place")}</span>
            <label>
              <input
                type="string"
                name="place"
                onChange={handleInputChange}
                value={place}
              />
              <span className="error-message d-inline ml-4">{" "}{errorElement === "place" ? errorMessage : ""}</span>
            </label>
          </div>

          <div className="form-item form-division">
            <span>{t("plan.drone.division")}</span>
            <label>
              <input
                type="string"
                name="division"
                onChange={handleInputChange}
                min="0"
                value={division}
              />
              <span className="error-message d-inline ml-4">{" "}{errorElement === "division" ? errorMessage : ""}</span>
            </label>
          </div>
        </div>

        <button className="btn-save-plan" disabled={isDisableBtnSubmit} onClick={handleSubmit}>
          {t("common.save")}
        </button>

        {action === "edit" &&
          <button className="btn-duplicate" onClick={handleDuplicatePlan} disabled={!isDisableBtnSubmit} >
            <img alt="duplicate-plan" src={require("../../assets/images/copy.png")} />
          </button>
        }
      </div>
    )
  }

  return (
    <div className="form-plan">
      {renderFormHeader()}
      <Tab.Container
        defaultActiveKey="view"
        activeKey={tabView}
        onSelect={handleChangeTab.bind(this)}
      >
        <div className="position-relative">
          <Nav variant="pills" className="flex-row">
            <Nav.Item>
              <Nav.Link eventKey="view">1 {t("plan.drone.tab_1")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="map-advance" disabled={"add" === action && !isDuplicate}>
                2 {t("plan.drone.tab_2_kiriha")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <Tab.Content
          bsPrefix="tab-content tabcontent-kiriha-mode"
          style={tabView === "view" ? { padding: "16px" } : null}
        >
          <Tab.Pane eventKey="view">
            <PlanFlightRouteSetting
              mainData={plan}
              action={action}
              plan={planRouterSetting}
              chargingStation={chargingStation}
              updatePlan={setPlanRouterSetting}
              handleInputChange={handleInputChange}
              errorMessage={errorMessage}
              errorElement={errorElement}
              setErrorMessage={setErrorMessage}
              setErrorElement={setErrorElement}
              isChange={statusChangeFlag.routerSt}
              onChangeRouterSt={() => setStatusChangeFlag(prev => ({ ...prev, routerSt: true }))}
              faceKirihaData={faceKirihaData}
              updateFaceKirihaData={setFaceKirihaData}
              markerKirihaData={markerKirihaData}
              updateMarkerKirihaData={setMarkerKirihaData}
              updateStatusChangeKirihaFlag={setStatusChangeKirihaFlag}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="map-advance">
            {plan != null &&
              <PlanMapKirihaMode
                tab={tabView}
                plan={plan}
                planRouterSetting={planRouterSetting}
                chargingStation={chargingStation}
                mapEditorContentKiriha={mapEditorContentKiriha}
                handleUpdateMapEditorContentKiriha={handleUpdateMapEditorContentKiriha}
                handleChangeImageFile={handleChangeImageFile}
                markerKirihaData={markerKirihaData}
                updateMarkerKirihaData={setMarkerKirihaData}
                onChangeRouterSt={() => {
                  setStatusChangeFlag(prev => ({ ...prev, routerSt: true }))
                  setStatusChangeKirihaFlag(prev => ({ ...prev, marker: true }))
                }}
              />
            }
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default PlanContent;